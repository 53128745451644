import { ethers } from "ethers";
import { sample } from "lodash";
import { NetworkMetadata } from "lib/wallets";

const { parseEther } = ethers.utils;

export const MAINNET = 56;
export const TESTNET = 97;
export const ETH_MAINNET = 1;
export const AVALANCHE = 43114;
export const AVALANCHE_FUJI = 43113;
export const ARBITRUM = 42161;
export const FANTOM = 250;
export const BSC = 56;

export const ARBITRUM_TESTNET = 421611;

// TODO take it from web3
export const DEFAULT_CHAIN_ID = BSC;
export const CHAIN_ID = DEFAULT_CHAIN_ID;

export const SUPPORTED_CHAIN_IDS = [FANTOM, BSC];

export const IS_NETWORK_DISABLED = {
  [FANTOM]: false,
};

export const CHAIN_NAMES_MAP = {
  [FANTOM]: "Fantom",
  [BSC]: "BNB Chain",
};

export const GAS_PRICE_ADJUSTMENT_MAP = {
  [FANTOM]: "30000000000", // 30 gwei
  [BSC]: "0",
};

export const MAX_GAS_PRICE_MAP = {
  [FANTOM]: "200000000000", // 200 gwei
  [BSC]: "200000000000", // 200 gwei
};

export const HIGH_EXECUTION_FEES_MAP = {
  [FANTOM]: 3, // 3 USD
  [BSC]: 3, // 3 USD
};

const constants = {
  [FANTOM]: {
    nativeTokenSymbol: "FTM",
    wrappedTokenSymbol: "WFTM",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.4"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.4"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.400001"),
  },
  [BSC]: {
    nativeTokenSymbol: "BNB",
    wrappedTokenSymbol: "WBNB",
    defaultCollateralSymbol: "BUSD",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.005"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.005"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.00500001"),
  },
};

export const RPC_PROVIDERS = {
  [FANTOM]: ["https://rpc.ankr.com/fantom"],
  [BSC]: ["https://bsc-dataseed.binance.org/"],
};

export const FALLBACK_PROVIDERS = {
  [FANTOM]: ["https://rpc.ftm.tools/"],
  [BSC]: ["https://rpc.ankr.com/bsc"],
};

export const NETWORK_METADATA: { [chainId: number]: NetworkMetadata } = {
  [FANTOM]: {
    chainId: "0x" + FANTOM.toString(16),
    chainName: "Fantom",
    nativeCurrency: {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[FANTOM],
    blockExplorerUrls: [getExplorerUrl(FANTOM)],
  },
  [BSC]: {
    chainId: "0x" + FANTOM.toString(16),
    chainName: "BNB Chain",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: FALLBACK_PROVIDERS[FANTOM],
    blockExplorerUrls: [getExplorerUrl(BSC)],
  },
};

export const CHAIN_ICONS = {
  [FANTOM]: {
    16: "ic_ftm_16.svg",
    24: "ic_ftm_24.svg",
    96: "ic_ftm_96.svg",
  },
  [BSC]: {
    16: "ic_bnb_16.svg",
    24: "ic_bnb_24.svg",
    96: "ic_bnb_96.svg",
  },
};

export const getConstant = (chainId: number, key: string) => {
  if (!constants[chainId]) {
    throw new Error(`Unsupported chainId ${chainId}`);
  }

  if (!(key in constants[chainId])) {
    throw new Error(`Key ${key} does not exist for chainId ${chainId}`);
  }

  return constants[chainId][key];
};

export function getChainName(chainId: number) {
  return CHAIN_NAMES_MAP[chainId];
}

export function getChainIcon(chainId: number, size: 16 | 24 | 96): string | undefined {
  return CHAIN_ICONS[chainId]?.[size];
}

export function getRpcUrl(chainId: number): string | undefined {
  return sample(RPC_PROVIDERS[chainId]);
}

export function getFallbackRpcUrl(chainId: number): string | undefined {
  return sample(FALLBACK_PROVIDERS[chainId]);
}

export function getExplorerUrl(chainId) {
  if (chainId === FANTOM) {
    return "https://ftmscan.com/";
  }

  switch (chainId) {
    case FANTOM:
      return "https://ftmscan.com/";

    case BSC:
      return "https://bscscan.com/";

    default:
      return "https://ftmscan.com/";
  }
}

export function getHighExecutionFee(chainId) {
  return HIGH_EXECUTION_FEES_MAP[chainId] || 3;
}

export function isSupportedChain(chainId) {
  return SUPPORTED_CHAIN_IDS.includes(chainId);
}
