import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";

import GlpSwap from "components/Glp/GlpSwap";
import "./BuyGlp.css";

import { Trans } from "@lingui/macro";
import { getNativeToken } from "config/tokens";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { PageHeader } from "components/PageHeader";
import { getChainName } from "config/chains";

export default function BuyGlp(props) {
  const { chainId } = useChainId();
  const history = useHistory();
  const chainIdOnlysearchParams = useMemo(() => new URLSearchParams(`chainId=${chainId}`), [chainId]);
  const [isBuying, setIsBuying] = useState(true);
  const nativeTokenSymbol = getNativeToken(chainId).symbol;
  const chainName = getChainName(chainId);

  useEffect(() => {
    const hash = history.location.hash.replace("#", "");
    const buying = hash === "redeem" ? false : true;
    setIsBuying(buying);
  }, [history.location.hash]);

  return (
    <div className="default-container page-layout BuyGlp">
      <PageHeader
        heading="Supply / Redeem LLP"
        url={"https://docs.lif3.com/lif3-trade/llp"}
        description={
          <>
            <Trans>
              Purchase <ExternalLink href="https://docs.lif3.com/lif3-trade/llp">LLP tokens</ExternalLink> to earn{" "}
              esLIF3 and {nativeTokenSymbol} fees from swaps and leverage trading.
            </Trans>
            <br />
            <Trans>Note: There is a minimum holding time of 24 hours after purchasing LLP.</Trans>
            <br />
            <Trans>
              View{" "}
              <Link
                to={{
                  pathname: "/earn",
                  search: chainIdOnlysearchParams.toString(),
                }}
              >
                staking
              </Link>{" "}
              page.
            </Trans>
          </>
        }
      />
      {chainName === "Fantom" && (
        <div className="App-warning">
          <Trans>LIF3 Trade on Fantom is on hold due to Multichain. Please withdraw your funds from the pool</Trans>
        </div>
      )}
      <GlpSwap {...props} isBuying={isBuying} setIsBuying={setIsBuying} />
    </div>
  );
}
